import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AccountModel } from "@models/open-area.models";
import { EventService } from "@services/infrastructure/event.service";

@Injectable({
    providedIn: 'root'
})
export class AccountService {

    static readonly userKey = 'ec.patient.care.v1.userProfile';
    static readonly userMetaKey = 'ec.patient.care.v1.userMeta';
    url = '@careApi/accountService';

    constructor(private httpClient: HttpClient,
                private eventService: EventService) {
    }

    static isInRole(roleName: string) {
        let roles = this.getUser()?.patientInfo?.roles ?? [];
        return roles.includes(roleName);
    }

    static isMerchantAvailable() {
        if (!this.isInRole("payment.all"))
            return true;

        return this.getUser()?.patientInfo?.merchantAvailable ?? false;
    }

    static getUser(): AccountModel | undefined {
        let jsonStr =
            localStorage.getItem(AccountService.userKey);
        if (!jsonStr)
            jsonStr = "{}";

        let userModel = JSON.parse(jsonStr);
        let castedModel = userModel as AccountModel;
        let isAuth = castedModel?.isAuthenticated ?? false;
        if (!isAuth)
            return undefined;

        let expiresOn = castedModel?.tokenInfo?.expiresOn;
        let expiresOnCasted = new Date(expiresOn);
        if (!expiresOnCasted)
            return undefined;

        let currentDate = new Date();
        if (expiresOnCasted < currentDate)
            return undefined;

        return userModel;
    }

    static isAuthenticated() {
        return AccountService.getUser()?.isAuthenticated ?? false;
    }

    generateToken(formVal: any) {
        let url = this.url.combineUrl("generateToken");
        return this.httpClient.post<any>(url, formVal);
    }

    saveUser(userModel?: AccountModel) {
        if (!userModel) {
            localStorage.removeItem(AccountService.userKey);
        } else {
            let jsonStr = JSON.stringify(userModel);
            localStorage.setItem(AccountService.userKey, jsonStr);
        }

        this.eventService.broadcast("user.update", userModel);
    }

    getUserInfo(token?: string) {
        let url = this.url.combineUrl("getUserInfo");
        if (token) {
            return this.httpClient.get<any>(url, {
                headers: {
                    "authorization": `Bearer ${token}`
                }
            });
        } else {
            return this.httpClient.get<any>(url);
        }
    }
}