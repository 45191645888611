import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from "rxjs";
import { AccountService } from "@services/account/account.service";
import { TenantInfoModel, TenantMetaData } from "@models/open-area.models";
import { EventService } from "@services/infrastructure/event.service";
import { map } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class TenantInfoService {

    static readonly userMetaKey = 'ec.patient.care.v1.userMeta';
    url = '@careApi/accountService';

    constructor(private httpClient: HttpClient,
                private eventService: EventService) {
    }

    static getTenantMetaData(): TenantMetaData | undefined {
        let jsonStr =
            localStorage.getItem(TenantInfoService.userMetaKey);
        if (!jsonStr)
            jsonStr = "{}";

        let userModel = JSON.parse(jsonStr);
        return userModel as TenantMetaData;
    }

    getTenants() {
        let url = this.url.combineUrl("getAllActiveTenants")
        return this.httpClient
            .get<string[]>(url)
            .pipe(map(x => x.map(y => y.toLowerCase())));
    }

    getTenantAnalytics(tenantId: string, referralCode: string): Observable<string[]> {
        let url = this.url.combineUrl('getTenantAnalyticsInfo');
        let params = new HttpParams();
        params = params.set("tenantId", tenantId);
        params = params.set("referralCode", referralCode);
        return this.httpClient.get<string[]>(url, {
            params: params
        });
    }

    getTenantInfo(): Observable<TenantInfoModel> {
        let userModel = AccountService.getUser();
        let tenantId = userModel?.tenantInfo?.identifier ?? "";
        if (tenantId) {
            return of(userModel.tenantInfo);
        }

        let userMetaData = TenantInfoService.getTenantMetaData();
        if (userMetaData.tenantIdentifier) {
            let url = this.url.combineUrl(`getTenantInfo?tenantId=${userMetaData.tenantIdentifier}`);
            return this.httpClient.get<TenantInfoModel>(url);
        }

        return of(undefined);
    }

    saveTenantMetaData(metaModel: TenantMetaData) {
        let jsonStr = JSON.stringify(metaModel);
        localStorage.setItem(AccountService.userMetaKey, jsonStr);
        this.eventService.broadcast("userMetaData.update", metaModel);
    }
}