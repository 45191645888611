import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class ToastService {

    constructor(private toastrService: ToastrService) {

    }

    success(message: string, title?: string, sticky?: boolean) {
        if (sticky)
            this.toastrService.success(message, title, { timeOut: 0 });
        else
            this.toastrService.success(message, title);
    }

    info(message: string, title?: string, sticky?: boolean) {
        if (sticky)
            this.toastrService.info(message, title, { timeOut: 0 });
        else
            this.toastrService.info(message, title);
    }

    error(message: string, title?: string, sticky?: boolean) {
        if (sticky)
            this.toastrService.error(message, title, { timeOut: 0 });
        else
            this.toastrService.error(message, title);
    }
}
