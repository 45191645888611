import { AppInfoModel, RouteDictionary, UserModel } from '@models/shared/shared-models';
import { apiUrlStore } from './api-url-store';

export const environment = {
    name: 'prd',
    hmr: false,
    serviceLocatorUrl: 'https://prd-svc-locator.euclidrcm.com',
    azureAd: {
        clientId: 'd93cf1df-2a9c-4d78-874a-53b45b702005'
    },
    urlStore: apiUrlStore,
    appInfo: {} as AppInfoModel,
    userInfo: {} as UserModel,
    routeDictionary: {} as RouteDictionary,
    activeTenants: [],
    isProd() {
        return this.name === 'prd';
    },
    recaptcha: {
        siteKey: '6LfDffcpAAAAADa_zTBtiZyy9Oq3LP7tsoc_mhCx',
    }
};