import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { FormEventModel, FormEventType } from '@models/shared/shared-models';

interface Event {
    type: string;
    payload?: any;
}

@Injectable({
    providedIn: 'root'
})
export class EventService {

    private handler = new Subject<Event>();
    private behaviorHandler = new BehaviorSubject<Event>(null);

    constructor() {
    }

    /**
     * Broadcast the event
     * @param type type of event
     * @param payload payload
     */
    broadcast(type: string, payload = {}) {
        this.handler.next({ type, payload });
    }

    broadcastBehavior(type: string, payload = {}) {
        this.behaviorHandler.next({ type, payload });
    }


    publishFormEvent(type: string, formEventType: FormEventType,
                     payload = {}) {
        if (!payload)
            payload = {};

        let eventModel = {
            formEventType: formEventType,
            data: payload
        } as FormEventModel;

        this.handler.next({ type, payload: eventModel });
    }

    /**
     * Subscribe to event
     * @param type type of event
     */
    listen(type: string) {
        return this.handler.pipe(
            filter(event => event && event.type === type))
            .pipe(
                map(event => event.payload));
    }

    listenForBehavior(type: string) {
        return this.behaviorHandler.pipe(
            filter(event => event && event.type === type))
            .pipe(
                map(event => event.payload));
    }
}
