import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { ProblemDetails } from "@models/shared/shared-models";
import { ToastService } from "@services/infrastructure/toast.service";

@Injectable({
    providedIn: 'root'
})
export class AppErrorService {

    constructor(private toastService: ToastService) {
    }

    static getControl(propName: string, formGroup: FormGroup): AbstractControl | null {
        propName = matchKey(formGroup, propName);
        let control = formGroup.get(propName);
        if (control)
            return control;

        let arrayName = parseArrayName(propName);
        control = formGroup.get(arrayName) as any;
        if (control)
            return control;

        let firstHalf = parseFirstHalf(propName);
        if (!firstHalf)
            return null;

        firstHalf = matchKey(formGroup, firstHalf);
        let customControl = formGroup.get(firstHalf) as any;
        if (!customControl)
            return null;

        let lastHalf = parseLastHalf(propName);
        if (!customControl.getControl)
            return null;

        return customControl.getControl(lastHalf);

        function parseFirstHalf(propName: string) {
            return propName.split('.')[0];
        }

        function parseLastHalf(propName: string) {
            let propArr = propName.split('.');
            if (propArr.length <= 1)
                return propName;

            propArr.shift();
            return propArr.join('.');
        }

        function parseArrayName(input: string): string {
            const match = input.match(/^[^\[]+/);
            return match ? match[0] : '';
        }

        function matchKey(formGroup: FormGroup, propName: string) {
            const normalizedControlName = propName.toLowerCase();
            const controlKeys = Object.keys(formGroup.controls);
            const foundKey =
                controlKeys.find(key => key.toLowerCase() === normalizedControlName);
            return foundKey ?? "";
        }
    }

    handleFormError(formGroup: FormGroup, error: HttpErrorResponse) {
        this.clearFormValidationError(formGroup, 'validationErrors');
        formGroup.markAllAsTouched();
        let parsedError = this.parseError(error);
        if (parsedError.errors && parsedError.status == HttpStatusCode.BadRequest) {
            let unIdentifiedErrors = this.settleValidationError(parsedError.errors, formGroup);
            let errorMessage = this.getValidationErrorMessage(unIdentifiedErrors);
            if (errorMessage)
                this.toastService.error(errorMessage, parsedError.title);
        } else {
            this.toastService.error(parsedError.detail, parsedError.title, true);
        }
    }

    settleValidationError(itemsToSettle: { [p: string]: string[] }, formGroup: FormGroup) {
        let unidentifiedItems: { [p: string]: string[] } = {};
        for (let propName of Object.keys(itemsToSettle)) {
            let propErrors = itemsToSettle[propName] ?? [];
            if (propErrors.length > 0) {
                let control = AppErrorService.getControl(propName, formGroup);
                if (control) {
                    control.setErrors({
                        validationErrors: propErrors
                    });

                    control.parent?.updateValueAndValidity();
                } else {
                    unidentifiedItems[propName] = propErrors;
                }
            }
        }

        return unidentifiedItems;
    }

    clearFormValidationError(control: AbstractControl, key?: string) {
        if (control instanceof FormGroup) {
            let castedControl = control as FormGroup;
            //clearError(castedControl, key);
            let controlKeys = Object.keys(castedControl.controls);
            controlKeys.forEach(controlKey => {
                let subControl = castedControl.get(controlKey);
                if (subControl)
                    this.clearFormValidationError(subControl, key);
            });
        } else if (control instanceof FormArray) {
            let castedControl = control as FormArray;
            //clearError(castedControl, key);
            let controlKeys = Object.keys(castedControl.controls);
            controlKeys.forEach(controlKey => {
                let subControl = castedControl.get(controlKey);
                if (subControl)
                    this.clearFormValidationError(subControl, key);
            });
        } else {
            clearError(control, key);
        }

        function clearError(myControl: AbstractControl, myKey?: string) {
            if (myKey) {
                if (myControl.hasError(myKey)) {
                    let hasErrorsLeft = false;
                    if (myControl && myControl.errors) {
                        delete myControl.errors[myKey];
                        hasErrorsLeft = Object.keys(myControl.errors).length > 0;
                    }

                    hasErrorsLeft ?
                        myControl.setErrors(myControl.errors) :
                        myControl.setErrors(null);
                }
            } else {
                if (myControl.errors)
                    myControl.setErrors(null);
            }
        }
    }

    handleError(error: HttpErrorResponse, fromInterceptor?: boolean) {
        let parsedError = this.parseError(error);
        if (parsedError.errors && !fromInterceptor) {
            let message = this.getValidationErrorMessage(parsedError.errors);
            if (message) {
                this.toastService.error(message, parsedError.title);
            } else {
                this.toastService.error(parsedError.detail, parsedError.title);
            }
        } else if (parsedError.status == HttpStatusCode.Forbidden ||
            parsedError.status == HttpStatusCode.Unauthorized) {
            this.toastService.error('Unauthorized Request', 'Error');
        } else if (parsedError.status == HttpStatusCode.NotFound) {
            if (!error.url?.contains('microsoft.com'))
                this.toastService.error('Page Not Found', 'Error');
        } else {
            this.toastService.error(parsedError.detail, parsedError.title);
        }
    }

    getValidationErrorMessage(valErrors: { [myKey: string]: string[] }) {
        let errorMessage = '';

        for (let valError in valErrors) {
            let errorMsgValue = valErrors[valError].join('<br/>');
            errorMessage += errorMsgValue;
        }

        return errorMessage;
    }

    parseError(error: HttpErrorResponse): ProblemDetails {
        let errorResp = error.error as ProblemDetails;
        if (errorResp?.traceId) {
            return errorResp;
        }

        return {
            appName: 'NotAvailable',
            traceId: '00-7139b73daab7e76adb15d10cc7e28e02-e94c3c3cd1748606-00',
            type: 'https://tools.ietf.org/html/rfc9110#section-15.6.1',
            detail: 'An unknown error occurred',
            exception: 'NotAvailable',
            instance: 'NotAvailable',
            title: 'An unknown error occurred',
            status: HttpStatusCode.InternalServerError
        }
    }

    //this special method allows us to ignore server side errors
    isFormValid(mainForm: FormGroup) {
        let returnVal = true;
        for (let controlName in mainForm.controls) {
            let control = mainForm.controls[controlName];
            let formGroup = control as FormGroup;
            if (returnVal && formGroup && formGroup.controls) {
                returnVal = this.isFormValid(formGroup);
            }

            let controlErrors = control.errors;
            for (let errorKey in controlErrors) {
                if (errorKey !== 'validationErrors' && returnVal) {
                    returnVal = false;
                }
            }
        }

        return returnVal;
    }
}